import React from 'react';
import { ProductContextProvider } from './src/context/product-context';
import { StoreProvider } from './src/context/store-context';
import { Toaster } from 'react-hot-toast';
import './src/styles/global.css';
import './src/styles/swiper-slider.css';

export const wrapRootElement = ({ element }) => (
  <ProductContextProvider>
    <StoreProvider>{element}</StoreProvider>
    <Toaster />
  </ProductContextProvider>
);
