import * as React from 'react';
import Client from 'shopify-buy';

const client = Client.buildClient({
  domain: process.env.SHOPIFY_STORE_URL,
  storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
});

const defaultValues: {
  cart: any[];
  isOpen: boolean;
  loading: boolean;
  onOpen: any;
  onClose: any;
  addVariantToCart: any;
  removeLineItem: any;
  updateLineItem: any;
  getProductById: any;
  client: any;
  checkout: any;
} = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: (variantId: any, quantity: any) => {},
  removeLineItem: (checkoutID: any, lineItemID: any) => {},
  updateLineItem: (checkoutID: any, lineItemID: any, quantity: any) => {},
  getProductById: (productId: any) => {},
  client,
  checkout: {
    lineItems: [],
  },
};

export const StoreContext = React.createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;

export const StoreProvider = ({ children }: any) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout);
  const [loading, setLoading] = React.useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false);

  const setCheckoutItem = (checkout: any) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id);
    }

    setCheckout(checkout);
  };

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser ? localStorage.getItem(localStorageKey) : null;

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(existingCheckoutID);
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout);
            return;
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, 'null');
        }
      }

      const newCheckout = await client.checkout.create();
      setCheckoutItem(newCheckout);
    };

    initializeCheckout();
  }, []);

  const addVariantToCart = (variantId: any, quantity: any): void => {
    setLoading(true);
    
    const checkoutID = checkout.id;

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];

    return client.checkout.addLineItems(checkoutID, lineItemsToUpdate).then((res: any) => {
      setCheckout(res);
      setLoading(false);
      setDidJustAddToCart(true);
      setTimeout(() => setDidJustAddToCart(false), 3000);
    });
  };

  const removeLineItem = (checkoutID: any, lineItemID: any): any => {
    setLoading(true);

    return client.checkout.removeLineItems(checkoutID, [lineItemID]).then((res: any) => {
      setCheckout(res);
      setLoading(false);
    });
  };

  const updateLineItem = (checkoutID: any, lineItemID: any, quantity: any): void => {
    setLoading(true);

    const lineItemsToUpdate = [{ id: lineItemID, quantity: parseInt(quantity, 10) }];

    return client.checkout.updateLineItems(checkoutID, lineItemsToUpdate).then((res: any) => {
      setCheckout(res);
      setLoading(false);
    });
  };

  const getProductById = async (productId: any) => {
    const product = await client.product.fetch(btoa(productId));
    return product;
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        getProductById,
        checkout,
        loading,
        didJustAddToCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
