import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { FooterProps } from './sanity-types/footer';
import { MainImageMetadataProps } from './sanity-types/main-image';
import { sanityConfig } from './sanityConfig';

export function mapEdgesToNodes(data: any): any {
  if (!data.edges) {
    return [];
  }

  return data.edges.map((edge: any) => edge.node);
}

export function getGatsbyImage(image: MainImageMetadataProps): JSX.Element | null {
  let gatsbyImage = null;

  if (image && image.asset) {
    const gatsbyImageData = getGatsbyImageData(image.asset, {}, sanityConfig);

    if (gatsbyImageData) {
      gatsbyImage = <GatsbyImage className={image.classes} image={gatsbyImageData} alt={image.altText} />;
    }
  }
  return gatsbyImage;
}

export function createFooterProps(siteConfig: any): FooterProps {
  const footerProps: FooterProps = {
    footerTagline: siteConfig.footerTagline,
    footerSocialMedia: siteConfig.footerSocialMedia,
    footerProductLinks: siteConfig.footerProductLinks,
    footerGeneralLinks: siteConfig.footerGeneralLinks,
    footerCompanyLinks: siteConfig.footerCompanyLinks,
  };

  return footerProps;
}
