import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { mapEdgesToNodes } from '../lib/helpers';

const defaultState = {
  products: [],
  collections: [],
};

export const ProductContext = React.createContext(defaultState);

export function ProductContextProvider({ children }: any) {
  const { allShopifyCollection, allShopifyProduct } = useStaticQuery(query);

  const products = mapEdgesToNodes(allShopifyProduct);
  const collections = mapEdgesToNodes(allShopifyCollection);

  return (
    <ProductContext.Provider
      value={{
        products,
        collections,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
}

const query = graphql`
  {
    allShopifyProduct {
      edges {
        node {
          ...ShopifyProductFields
        }
      }
    }
    allShopifyCollection(sort: { fields: title, order: ASC }) {
      edges {
        node {
          products {
            ...ShopifyProductFields
          }
          title
          description
          shopifyId
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
